import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "font-awesome/css/font-awesome.min.css"
import "../styles/bootstrap.scss"
import "../components/covid.css"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Tin`,
        `Pan`,
        `Valley`,
        `Consulting`,
        `Software`,
        `Development`,
      ]}
    />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
        crossorigin="anonymous"
      />
    </Helmet>
    <article className="home">
      <Card className="Card">
        <CardContent>
          <div className="media">
            <h1>Tin Pan Valley</h1>
            <div className="content">
              <p style={{ textTransform: "uppercase" }}>Software Development</p>
              <p>by</p>
              <p style={{ fontFamily: "Open Sans, sans-serif" }}>
                <a href="https://hiester.com/">Chris Hiester</a>
              </p>

              <div style={{ padding: "2rem 0" }} className="footer">
                <p>
                  &copy; 2004-2021 Tin Pan Valley Consulting, LLC. All Rights
                  Reserved.
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </article>
  </Layout>
)

export default IndexPage
